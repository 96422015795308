import { useState, useCallback } from 'react'

const useInput = initialValue => {
  const [value, setValue] = useState(initialValue || '')

  const reset = useCallback(() => setValue(''), [])
  const onChange = useCallback(event => setValue(event.target.value), [])

  return {
    value,
    setValue,
    reset,
    bind: {
      value,
      onChange,
    },
  }
}

export default useInput
