import { useEffect } from 'react'
import classNames from 'classnames'
import noop from 'lodash/noop'

import importEverlaneIcon, { Icon } from 'lib/iconProps'

import styles from './styles.module.scss'

const X = importEverlaneIcon(Icon.X)

type CloseProps = {
  className?: string
  onClick: () => void
}

const CloseButton = ({ className, onClick }: CloseProps) => {
  useEffect(() => {
    const handleClick = event => {
      if (event.key === 'Escape') {
        onClick()
      }
    }

    global.document.addEventListener('keydown', handleClick)

    return () => global.document.body.removeEventListener('keypress', handleClick)
  }, [onClick])

  return (
    <button
      onTouchStart={noop} // for the active pseudo class to work on mobile safari
      type="button"
      onClick={onClick}
      className={classNames(className, styles['close-button'])}
    >
      <X height="24px" width="24px" />
    </button>
  )
}

export default CloseButton
