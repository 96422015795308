import Link from 'next/link'

import useGeolocation from 'lib/network/hooks/useGeolocation'
import isGDPRCountryCode from 'lib/helpers/is_gdpr_country_code'

import styles from './styles.module.scss'

const PolicyAndTerms = () => {
  const { data: geolocation } = useGeolocation()

  return (
    <div className={styles['policy-and-terms']}>
      {geolocation && !isGDPRCountryCode(geolocation.countryCode) && (
        <span>New user discount applies only to full price items.</span>
      )}
      <p>
        By providing your email address, you agree to our{' '}
        <Link
          href="/privacy"
          prefetch={false}
          target="_blank"
          className={styles['policy-and-terms__link']}
        >
          Privacy Policy
        </Link>{' '}
        and{' '}
        <Link
          href="/terms"
          prefetch={false}
          target="_blank"
          className={styles['policy-and-terms__link']}
        >
          Terms of Service
        </Link>
        .
      </p>
    </div>
  )
}

export default PolicyAndTerms
